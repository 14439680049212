<template>
    <div class="zhizhao">
        <other-header></other-header>
        <div class="office-detail">
            <detail-header last-name="返回" title="营业执照 人力资源服务许可证"/>
            <img src="@/assets/images/zhizhao.jpg" alt="">
<!--            <img src="@/assets/images/xukezheng.png" alt="">-->
        </div>
        
    </div>
</template>

<script>
// zhizhao
import OtherHeader from "@/components/other-header";
import DetailHeader from "@/components/detail-header";
export default {
    name: "zhizhao",
    components: {DetailHeader, OtherHeader},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {}
}
</script>

<style scoped lang="less">
.zhizhao {
    & .office-detail {
        width: calc(22rem);
        margin: 0.4rem auto;
        & img {
            width: 100%;
            margin-bottom: 0.4rem;
        }
    }
}
</style>
